<template>
  <div class="Progression">
    <Header title="Progression" app />
    <div class="container">
      <div class="loader" v-if="!user">
        <Spinner />
      </div>

      <EmptyState
        v-else-if="user.events.length == 0 || user.agenda.length == 0"
      />

      <div class="timeline__container" v-else>
        <div class="timeline__conseil">
          <h4>Voici nos conseils pour votre traitement du moment</h4>
          {{ conseil }}
        </div>
        <div class="timeline">
          <div
            class="timeline__event"
            v-for="event in user.events"
            :key="event.id"
            :id="event.id"
            @click="eventHandler(event)"
          >
            <div class="timeline__image">
              <img :src="getImgUrl(event.type)" alt="image" />
            </div>
            <div class="timeline__text">
              <p>{{ event.type }}</p>
              <p>
                {{ event.nbrSeance
                }}{{ parseInt(event.nbrSeance) > 1 ? " séances" : " séance" }}
              </p>
              <p>
                {{
                  event.dateDebut
                    .split("-")
                    .reverse()
                    .join("-")
                }}
              </p>
            </div>
            <!-- Marker in progress -->
            <div
              class="timeline__marker timeline__marker--progress marker__position"
              v-if="remainingDays(event.dateFin) > 0"
            >
              <svg viewBox="0 0 12 13">
                <g transform="translate(-48 -31.835)">
                  <ellipse
                    cx="1.5"
                    cy="2"
                    rx="1.5"
                    ry="2"
                    transform="translate(53 31.835)"
                    fill="#fff"
                  />
                  <circle
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(48 37.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cy="0.5"
                    ry="0.5"
                    transform="translate(58 34.835)"
                    fill="#fff"
                  />
                  <path
                    d="M95.788,92.995a1.579,1.579,0,0,0-2.248,0,1.616,1.616,0,0,0,0,2.269,1.579,1.579,0,0,0,2.248,0A1.616,1.616,0,0,0,95.788,92.995Z"
                    transform="translate(-43.67 -58.772)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="0.5"
                    cy="1"
                    rx="0.5"
                    ry="1"
                    transform="translate(59 37.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="1"
                    cy="0.5"
                    rx="1"
                    ry="0.5"
                    transform="translate(57 41.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="1.5"
                    cy="1"
                    rx="1.5"
                    ry="1"
                    transform="translate(53 42.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="1"
                    cy="1.5"
                    rx="1"
                    ry="1.5"
                    transform="translate(50 40.835)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <!-- Marker in done -->
            <div class="timeline__marker marker__position" v-else>
              <svg viewBox="0 0 14.054 10.222">
                <path
                  d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1.022,1.022,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16a1,1,0,0,0,0-1.47Z"
                  transform="translate(-4.978 -6.914)"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyState from "../components/EmptyState.vue";
import Header from "../components/Header.vue";
import Spinner from "../components/Spinner.vue";
export default {
  components: { Spinner, Header, EmptyState },
  computed: {
    user() {
      console.log(this.$store.getters.user);
      return this.$store.getters.user;
    },
    conseil() {
      const events = this.user.events;
      const event = events[events.length - 1];
      const conseils = this.$store.state.conseils;

      const conseilsOfEvent = conseils.filter(
        el => el.name.toLowerCase() == event.type.split(" ")[0].toLowerCase()
      );

      const randomConseil =
        conseilsOfEvent[0].data[
          Math.floor(Math.random() * conseilsOfEvent[0].data.length)
        ];

      return randomConseil;
    }
  },
  methods: {
    getImgUrl(pic) {
      // Get the images
      switch (pic) {
        case "Découverte de la maladie":
          return require("../assets/progression/decouverte.svg");
        case "Chimiothérapie taxotere":
          return require("../assets/progression/chimiotherapie_taxotere.svg");
        case "Chimiothérapie epirubicine":
          return require("../assets/progression/chimioterapie_epirubicine.svg");
        case "Radiothérapie":
          return require("../assets/progression/radiotherapie.svg");
        case "Chimiothérapie xeloda":
          return require("../assets/progression/chimioterapie_xeloda.svg");
        case "Chirurgie":
          return require("../assets/progression/reconstruction.svg");
        case "1 an de traitement":
          return require("../assets/progression/1an_de_traitement.svg");
      }
    },
    remainingDays(date) {
      const date1Array = new Date()
        .toJSON()
        .slice(0, 10)
        .split("-")
        .reverse();
      const date2Array = date.split("-");
      const date1 = new Date(date1Array[2], date1Array[1] - 1, date1Array[0]);
      const date2 = new Date(date2Array[0], date2Array[1] - 1, date2Array[2]);
      console.log(date2Array);
      const one_day = 1000 * 60 * 60 * 24;
      return Math.ceil((date2.getTime() - date1.getTime()) / one_day);
    },
    eventHandler(event) {
      this.$store.dispatch("popupEventOpen", event);
    }
  }
};
</script>

<style lang="scss">
.timeline {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 40px;

  &::before {
    content: "";
    background-color: #95cdcb;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 2;
    transform: translateX(50%);
  }
  &__conseil {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.13);
    h4 {
      color: #95cdcb;
      font-size: 20px;
      font-family: "Lora", serif;
      padding-bottom: 10px;
    }
  }

  &__image {
    img {
      height: 150px;
      width: 150px;
    }
  }

  &__text {
    height: 100%;
    position: relative;
    text-transform: uppercase;
    padding: 14px 20px;
  }

  &__event {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.05);
    text-align: left;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      color: #95cdcb;
      &:first-child {
        color: #a5a5a5;
        margin-bottom: 20px;
      }
    }
  }

  &__marker {
    background-color: #5e8584;
    position: absolute;

    width: 26px;
    height: 26px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 3;
    margin-bottom: 5px;

    &--progress {
      background-color: #fad1d1;
    }

    svg {
      width: 14px;
    }
  }
}
.marker {
  &__position {
    position: absolute;
    bottom: 15px;
    right: 8px;
  }
}

@media (min-width: 850px) {
  .timeline {
    width: 90%;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 40px;
    column-gap: 20px;
    margin: auto;
    overflow-x: scroll;

    &::before {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    &__container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      height: 100%;
      top: 20%;
      position: relative;
    }
    &__conseil {
      width: 30em;
      h4 {
        width: 17em;
        font-size: 22px;
        padding-bottom: 20px;
        margin: auto;
      }
    }

    &__event {
      margin-bottom: 0;
      position: relative;
      &::before {
        content: "";
        background-color: #95cdcb;
        position: absolute;
        width: calc(100% + 20px);
        height: 3px;
        top: unset;
        right: unset;
        bottom: 25px;
        left: 0;
        z-index: 1;
        transform: translateY(50%);
      }

      &:last-child {
        &::before {
          width: 100%;
        }
      }
    }
    &__image {
      img {
        height: 150px;
        width: 150px;
      }
    }
  }
  .marker {
    &__position {
      position: absolute;
      bottom: 10px;
      right: 30px;
    }
  }
  .container {
    width: 90%;
    padding: 0px 0px 0px 60px;
    margin: auto;
  }
}
</style>
